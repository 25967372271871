<template>
  <b-app :dark="dark" :style="{ backgroundColor: site_background }">

    <template v-slot:navigation>
      <v-app-bar
        app
        dark
        color="primary"
        shrink-on-scroll
        src="/images/person-card.jpg"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            position="center center"
            gradient="to bottom right, rgba(43,116,183,.5), rgba(24,57,99,.8)"
          ></v-img>
        </template>

        <v-toolbar-title>{{ $t("top_bar.title") }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <b-language-dropdown />

      </v-app-bar>
    </template>

    <template v-slot:default>
      <v-container fluid>
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            lg="10"
            offset-lg="1"
            xl="8"
            offset-xl="2"
          >
            <router-view v-if="!config_error"/>
          </v-col>
        </v-row>
      </v-container>
      <b-error-alert v-model="config_error" :message="error_message"/>
      <b-development-banner
        :visible="dev_mode"
      >
        <b-language-debug-toggle
          class="debug ml-6"
        />
      </b-development-banner>
    </template>

    <template v-slot:footer>
      <v-footer dark padless>
        <v-card
          flat
          tile
          color="primary"
          class="white--text text-center b-full-width"
        >
          <v-card-text class="white--text" v-if="$t('footer.text')">
            {{ $t("footer.text") }}
          </v-card-text>
          <v-divider v-if="$t('footer.text')"/>
          <div class="d-flex justify-center">
            <p class="footer-copyright white--text" v-html="$t('footer.developed_by')" />
            <p class="footer-copyright white--text">
              <router-link :to="{ name: 'Privacy'}">{{ $t("footer.privacy") }}</router-link>
            </p>
            <p class="footer-copyright white--text">
              <router-link :to="{ name: 'Impressum'}">{{ $t("footer.impressum") }}</router-link>
            </p>
          </div>
        </v-card>
      </v-footer>
    </template>

  </b-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({
    config_error: false,
    error_message: "",
    dark: !!window.bomconfig.darkmode,
  }),
  created() {
    if (!window.bomconfig) {
      this.setError(this.$t("b.error.no_bom_config"));
      return;
    }
    this.assertRequiredConfigs();
    if (window.bomconfig.color) {
      this.$bomify.$vuetify.theme.themes.light.primary = window.bomconfig.color;
      this.$bomify.$vuetify.theme.themes.dark.primary = window.bomconfig.color;
    }
    if (window.bomconfig.add_modules) {
      window.bomconfig.add_modules.forEach((module_definition) => {
        if (!this.$bom[module_definition.name]) {
          this.$bom.addModule(module_definition);
        }
      });
    }
  },
  computed: {
    dev_mode() {
      return !window.bomconfig.production;
    },
    ...mapGetters("config", {
      getThemeVariable: "getThemeVariable",
    }),
    site_background() {
      return this.getThemeVariable("site_background");
    },
  },
  methods: {
    assertRequiredConfigs() {
      const required_config_keys = [
        "api_url",
        "api_token",
        "client_id",
        "module",
      ];
      required_config_keys.forEach((required_key) => {
        this.assertConfig(required_key);
      });
    },
    assertConfig(entry) {
      if (!window.bomconfig[entry]) {
        this.setError(this.$t("b.error.missing_config_entry", { entry }));
      }
    },
    setError(message) {
      this.config_error = true;
      this.error_message = `ERROR: ${message}`;
    },
  },
};
</script>

<style>
.debug >>> .v-label {
  color: black;
  font-size: 16px;
}
.debug >>> .v-input--selection-controls__input * {
  font-size: 20px;
}
.footer-copyright a {
  color: #fff !important;
}
.footer-copyright {
  padding: 10px 20px 0 20px;
}
</style>
