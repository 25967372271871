import Home from "../pages/Home.vue";
import TransferInput from "../pages/TransferInput.vue";
import Impressum from "../pages/Impressum.vue";
import Privacy from "../pages/Privacy.vue";
import SecondRegistration from "../pages/SecondRegistration.vue";

export default [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/transfer-code",
    name: "TransferInput",
    component: TransferInput,
  },
  {
    path: "/second-registration",
    name: "SecondRegistration",
    component: SecondRegistration,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
];
