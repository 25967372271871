<template>
  <div>
    <div class="pt-6">
      <h3>{{ $t("pages.transfer_code.subtitle") }}</h3>
      <p class="mb-0">{{ $t("pages.transfer_code.text") }}</p>
    </div>
    <b-edit-box
      :edit-object="bom_reference"
      :editable="true"
      start-in-edit-mode
      hide-cancel-button
      save-function-name="preSave"
      @saved="saved"
    >
      <template v-slot:default="attrs">
        <b-foreignkey-text-input
          v-bind="attrs"
          show-always
          :validation-rules="rules"
          label="Covid Certificate Transfer Code"
          fknumber="930029"
          fkcategory="Transfer Nummer"
          field="name"
        />
      </template>
    </b-edit-box>
  </div>
</template>

<script>
import { bomify } from "@polygon-software/bomify";

// http://localhost:8080/#/transfer-code?prev-reference-id=18784%23Nqv1NobrRPVpNFY0F7Jy6Tz5JTPQQV6cwwsWy1Ek%3D
// Valid: K42K6F7R2
// Invalid: 3B8YDAZYS
export default {
  name: "TransferInput",
  data: () => ({
    input: "",
    bom_reference: undefined,
    rules: [
      bomify.$rules.required(),
      bomify.$rules.minLength(9),
      bomify.$rules.maxLength(9),
      bomify.$rules.covidTransferCode(),
    ],
  }),
  created() {
    this.$bomify.$leaveguard.activate();
    this.$bom.event.references.one
      .withId(this.$route.query["prev-reference-id"])
      .including("ForeignKeysPlugin")
      .fetch()
      .then(({reference}) => {
        this.bom_reference = reference;
      });
  },
  methods: {
    saved() {
      this.$bomify.$leaveguard.deactivate();
      window.close();
      setTimeout(() => this.$bomify.$notification.notifySuccess(this.$t("pages.transfer_code.success")), 500);
    },
  },
  head: {
    title() {
      return {
        inner: this.$t("pages.trasnfer_code.tab"),
      };
    },
  },
};
</script>

<style scoped>

</style>
