<template>
  <div>
    <h1>{{ $t("impressum.title") }}</h1>
    <p v-html="$t('impressum.text')" />
  </div>
</template>

<script>
export default {
  name: "Impressum",
  head: {
    title() {
      return {
        inner: this.$t("impressum.title"),
      };
    },
  },
};
</script>

<style scoped>

</style>
