<template>
  <div class="mb-8">
    <div class="py-6">
      <h3>{{ $t("pages.second_registration.subtitle") }}</h3>
      <p>{{ $t("pages.second_registration.text") }}</p>
    </div>
    <b-loading-circle v-if="loading"/>
    <b-daily-planner
      v-else
      hide-full-events
      hide-past-events
      hide-events-after-registration-deadline
      module="event"
      object-type="instances"
      :preselected-date="start_date"
      :disable-dates-before="start_date"
      :disable-dates-after="end_date"
      @select="eventSelected"
    />
  </div>
</template>

<script>
import { addDays, set } from "date-fns";

// http://localhost:8080/#/second-registration?prev-instance-id=32268%238tPvH32pS2GnsSvma1GN84iwtVvhtR0lZvBNn6Y58%3D
export default {
  name: "SecondRegistration",
  data: () => ({
    loading: true,
    start_date: undefined,
    end_date: undefined,
  }),
  created() {
    this.$bomify.$leaveguard.activate();
    this.$bom.event.instances.one
      .withId(this.$route.query["prev-instance-id"])
      .fetch()
      .then(({ instance }) => {
        this.start_date = set(addDays(instance.from, window.bomconfig.min_days_after_first_event), {
          hours: 0, minutes: 0, seconds: 0,
        });
        this.end_date = set(addDays(instance.from, window.bomconfig.max_days_after_first_event), {
          hours: 0, minutes: 0, seconds: 0,
        });
        this.loading = false;
      });
  },
  methods: {
    eventSelected($event) {
      this.$bomify.$leaveguard.deactivate();
      const instanceId = $event.id;
      const url = `${window.bomconfig.second_registration_regmask_url}#/?id=${encodeURIComponent(instanceId)}`;
      window.open(url, "_blank");
    },
  },
  head: {
    title() {
      return {
        inner: this.$t("pages.second_registration.tab"),
      };
    },
  },
};
</script>

<style scoped>

</style>
