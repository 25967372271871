import "@polygon-software/bomify/dist/bomify.css";

import Vue from "vue";
import VueHead from "vue-head";
import bomify from "@polygon-software/bomify";

import routes from "./router/routes.js";
import empty from "./store/empty.js";
import de from "./languages/de.js";
import fr from "./languages/fr.js";
import en from "./languages/en.js";

import App from "./App.vue";

// bom-framework parameters
const bom = {
  api_url: window.bomconfig.api_url,
  api_token: window.bomconfig.api_token,
  client_name: window.bomconfig.client_name,
  client_id: window.bomconfig.client_id,
  language: window.bomconfig.language,
};

Vue.use(bomify, bom);
Vue.use(VueHead, {
  separator: "-",
  complement: "JDMT",
});

// Vuetify Theme
const theme = {
  themes: {
    dark: {
      primary: "#fa9615",
      secondary: "#fa9615",
      accent: "#ff6666",
    },
  },
};

// i18n languages
const languages = {
  de,
  fr,
  en,
};

// Vuex Store Modules
const store_modules = {
  empty,
};

const bomify_plugins = bomify.plugins({
  bom, theme, languages, routes, store_modules,
});

new Vue({
  ...bomify_plugins,
  render: (h) => h(App),
}).$mount("#app");
