<template>
  <div class="mb-8">
    <div class="py-6">
      <h3>{{ $t("pages.home.subtitle") }}</h3>
      <p>{{ $t("pages.home.text") }}</p>
    </div>
    <b-daily-planner
      hide-full-events
      hide-past-events
      :consider-registration-deadline="false"
      :query="query"
      :preselected-date="today"
      :disable-dates-before="today"
      :preload-days="preload_days"
      @select="eventSelected"
    />
  </div>
</template>

<script>
import { bomify } from "@polygon-software/bomify";

export default {
  name: "Home",
  data: () => ({
    today: bomify.$filters.date.to_bom_timezone(new Date()),
    preload_days: window.bomconfig.preload_days || 0,
  }),
  methods: {
    eventSelected($event) {
      const instanceId = $event.id;
      const url = `${window.bomconfig.first_registration_regmask_url}#/?id=${encodeURIComponent(instanceId)}`;
      window.open(url, "_blank");
    },
  },
  computed: {
    query() {
      const q = this.$bom.event.instances.all;
      if (window.bomconfig.daily_planner_tags) {
        q.withTags(...window.bomconfig.daily_planner_tags);
      }
      return q;
    },
  },
  head: {
    title() {
      return {
        inner: this.$t("pages.home.tab"),
      };
    },
  },
};
</script>

<style scoped>

</style>
